.code {
  margin-bottom: 1.5625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowInput {
  width: 37%;
}

.rowDropdown {
  width: 27%;
}

.date {
  width: 30%;
}

.checkbox {
  margin-top: 1.5625rem;
}

.time {
  margin-top: 0.9375rem;
  width: 6.25rem;
}

.chestZnBalance {
  height: 1.25rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: #201f1e;
}
