.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}
.infoHeader {
  width: 100%;
  margin-top: 1.875rem;
  background: #ffffff;
  border-radius: 4px;
  padding: 0.9375rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoTitle {
  font-size: 0.875rem;
  line-height: 1rem;
}

.infoIcon {
  font-size: 1rem;
  color: #0078d4;
  font-weight: 700;
}

.infoContent {
  width: 100%;
  background: #fff;
  padding: 0.9375rem 1.25rem;
  display: none;
  max-height: 31.25rem;
  overflow: auto;
}

.infoContentShowed {
  display: block;
}

.infoRow {
  width: 100%;
}

.infoKey {
  text-decoration: underline;
  width: 100%;
  margin-bottom: 0.625rem;
}

.infoValue {
  width: 100%;
  font-size: 0.875rem;
}

.signIcons {
  width: 1.125rem;
  height: auto;
}
