.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm {
  height: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-left: auto;
}
