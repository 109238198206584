@import '../../assets/scss/mixins';
@import '../../assets/scss/vars';

.table {
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  margin: 0.5rem;
  height: 100%;
}

.tableContent {
  overflow: auto;
  position: relative;
  background: #FFF;
  min-width: 100%;
  height: 100%;
}

.tableHeader {
  display: flex;
  align-items: center;
  background: #FFF;
  border-bottom: 1px solid #e9e9e9;
  padding-right: 0.4375rem;
  min-width: 100%;
}

.tableHeaderItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  padding: 0 8px;
  margin: 0;
  background: #FFF;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dragging {
    background: #f0f0f0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .table {

    & div,
    button,
    p,
    span {
      font-size: 0.65rem;
    }
  }
}

.loaderContainer {
  width: 100%;
  min-height: 100%;
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchNothing {
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-bottom: 1px solid #e9e9e9;
  padding: 0 0.4375rem 0 0;
  overflow: hidden;
}

.sortable {
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.sortIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

@include scroll('.tableContent');

.resizeHandle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  cursor: col-resize;
  user-select: none;
  transition: background 0.2s ease; // Добавлена анимация

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 40%;
    width: 1px;
    background: #e9e9e9;
  }
}

.resizing {

  cursor: col-resize;
  user-select: none;

  * {
    user-select: none;
  }

  .resizeHandle {
    &::after {
      background: #0078D4;
      top: 15%;
      left: 30%;
      bottom: 15%;
      width: 2px;
    }
  }
}

@keyframes draggingAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.tableCell {
  &.dragging {
    background: #f0f0f0;
    animation: draggingAnimation 0.3s ease;
  }
}

.draggingColumn {
  background: rgba(0, 120, 212, 0.05);
  transition: all 0.2s ease;

  &.dragging {
    background: rgba(0, 120, 212, 0.1);
    transform: scale(1.01);
  }
}