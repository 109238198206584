@import '../../../assets/scss/mixins.scss';

.modal {
  padding: 1.5rem;
  max-height: 78vh;
  overflow: auto;
  min-width: 40vw;
  max-width: 40vw;
  overflow-y: auto;

  &>div {
    overflow: visible;
  }

  .scrollableContent-242,
  .scrollableContent-194 {
    overflow-y: hidden;
  }
}

.label {
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  width: 100%;
}

.textInput {
  width: 100%;
  margin-bottom: 0.5rem;
}

.btn {
  width: 50%;
  margin-top: 1rem;
}

.mainButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.3125rem;
}

.newSelect {
  margin-top: 1.5rem;
  width: 100%;
  overflow: visible;
  height: 100%;
  // font-family: 'Segoe UI';
  // font-size: 12px;
  // line-height: 1;
  // border: 0.5px solid black;
  z-index: 10000;
  background-color: orange;
  position: absolute;
  top: 0;
  left: 0;
}

@include scroll('.modal');