@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro'),
    url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf') format('truetype'),
    url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro'),
    url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf') format('truetype'),
    url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FabricMDL2Icons';
  src: local('FabricMDL2Icons'),
    url('../../assets/fonts/fonts-files/FabricMDL2Icons.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FabricMDL2Icons-0';
  src: local('FabricMDL2Icons-0'),
    url('../../assets/fonts/fonts-files/FabricMDL2Icons-0.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FabricMDL2Icons-1';
  src: local('FabricMDL2Icons-1'),
    url('../../assets/fonts/fonts-files/FabricMDL2Icons-1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}