@import '../../assets/scss/animations';
@import '../../assets/scss/vars';

.waitingInfo {
  background: #fff;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 550px;
}

.title {
  margin: 0.9375rem 0;
  font-weight: 500;
}

.icon {
  color: $accent;
  font-size: 1.25rem;
  animation: rotate 1s linear infinite alternate-reverse;
}
