.infoRow {
  width: 100%;
}

.infoKey {
  text-decoration: underline;
  width: 100%;
  margin-bottom: 0.625rem;
}

.infoValue {
  width: 100%;
  font-size: 1rem;
}

.infoContent {
  width: 100%;
  background: #fff;
  padding: 0.9375rem 1.25rem;
  max-height: 31.25rem;
  overflow: auto;
}

.markingCodesTitle {
  margin-top: 3.125rem !important;
  display: inline-block;
}

.code {
  display: block;
  font-size: 0.875rem;
}
