.checkBoxWrapper {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  margin: 2rem 2rem;
}

.singleCheckbox {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.checkboxText {
  text-align: center;
}

.addButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
}

.title {
  margin-bottom: 1.25rem;
}

.form {
  width: 100%;
}

.company,
.suz,
.gismt,
.catalog {
  margin-bottom: 40px;
  width: 90%;
}

.infoRow {
  width: 40rem;
  margin-bottom: 1.875rem;

  &Inputs {
    display: flex;
  }

  &Title {
    font-weight: 700;
  }
}

.templates {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;
}

.templateInput {
  width: 300px;
  margin-right: 1.875rem;

  &:last-child {
    margin-right: 0;
  }
}

.rowDropdown {
  width: 15rem;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0;
}

.singleCheckbox {
  margin: 1rem 0 0 0;
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.checkboxText {
  text-align: center;
  font-size: 14px;
}

.fieldTitle {
  font-size: 14px;
  font-weight: 600;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0 1.5rem 0;
}

.tableActionCell {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;



  &>div:first-child {
    display: flex;
    justify-content: flex-start;

    >:first-child {
      padding-left: 1rem;
    }
  }

  & div,
  p,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &>div:first-child {
    display: flex;
    justify-content: flex-start;
  }
}

.tableCell {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.mainContent {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tableHeader {
  div>div {
    justify-content: center;
  }

  & :first-child>div {
    justify-content: flex-start;
  }

  &>div>div,
  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.checkBoxWrapper {
  margin: 0 1rem;
}