.infoRow {
  position: relative;
}
.preliminaryPrint {
  width: 40%;
  max-width: 100%;
}

.chekboxContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}
