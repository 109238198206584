@import '../../assets/scss/vars';

// @font-face {
//   font-family: 'AkzidenzGroteskPro';
//   src: local('AkzidenzGroteskPro'),
//     url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf') format('truetype'),
//     url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

.header {
  height: $headerHeight;
  width: 100%;
  background: $accent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.375rem;
  color: $gray;
}

.mainInfo {
  width: 100%;
  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  // width: 11rem;
  flex-shrink: 0;
  // margin-right: 10rem;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
  align-self: center;
  font-family: 'AkzidenzGroteskPro-Regular', 'Segoe UI', sans-serif;
  // margin-left: 1.5rem;

  // margin-top: 1rem;
}

.logoText {
  font-family: 'AkzidenzGroteskPro';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.145em;
  color: #ffffff;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #f8f8f8;
  margin-bottom: 0.1875rem;
  margin-left: 5rem;
}

@media (max-width: 900px) {
  .title {
    font-size: 1rem
  }
}

@media (max-width: 800px) {
  .title {
    font-size: 0.8rem;
    margin-left: 2rem;
  }
}

.titleLink {
  margin-right: 3%;
}

.controls {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.9375rem;
  color: $gray;
}

.icon {
  font-size: 1rem;
  width: 1.125rem;
  height: auto;
  align-self: center;
}

.help {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f4;
}

.name {
  background: $gray;
  color: $accent;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 2.125rem;
  text-transform: uppercase;
  position: relative;

  &:hover {
    .logout {
      opacity: 1;
    }
  }
}

.nameText {
  margin-right: 1.25rem;
}

.logout {
  position: absolute;
  background: $gray;
  color: $accent;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  opacity: 0;
}

.logoutIcon {
  width: 1.125rem;
  height: auto;
  align-self: center;
}

svg {
  stroke-width: 3px;
}

.noDisplay {
  display: none;
  position: fixed;
}

@mixin balanceButton($background-color: transparent) {
  min-width: 11.25rem;
  height: 1.875rem;
  border-radius: 1rem;
  background-color: $background-color;
}

.balance {
  @include balanceButton();
  color: $gray;
  border: 1px solid $gray;

  &:hover {
    background-color: $gray;
    color: $accent;
  }
}

.balanceText {
  max-width: 9.375rem;
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 2.25rem;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.balanceLow {
  @include balanceButton(#f96d6d);
  color: $gray;

  &:hover {
    border: 1.5px solid #ffffff;
  }
}

.signOutIcon {
  width: 90%;
  height: auto;
}