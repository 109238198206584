@import '../../assets/scss/vars';

.syncRes {
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  background-color: #fff;
  align-items: center;
}

.icon {
  font-size: 2.25rem;
}

.errorIcon {
  color: $red;
}

.successIcon {
  color: $green;
}

.syncStatusText {
  margin: 0.9375rem 0 1.25rem;
  font-weight: 500;
  color: $green;
  animation: fadeIn .5s ease-out forwards;
}

.syncStatusErrorText {
  color: $red;
}
