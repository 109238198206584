@import '../../assets/scss/vars';

.container {
  position: fixed;
  top: $headerHeight;
  left: 0;
  width: 100%;
  height: calc(100% - #{$headerHeight});
  background: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  width: 100%;
}

.confirm {
  height: auto;
  width: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iframe {
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

.alert {
  background: #fff;
  padding: 0.9375rem 1.5625rem 0.9375rem;
  border-radius: 5px;
  // min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100%;
  width: 90rem;
  height: 45rem;
  text-align: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.btn {
  margin-right: 0.625rem;
  width: 9.375rem;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 30rem;
}

.row {
  display: flex;
}
