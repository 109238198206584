.autocreateOrderWrapper {
  display: flex;
  flex-direction: column;
}

.singleCheckbox {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.checkboxText {
  text-align: center;
}

.texFieldsWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  gap: 0.5rem;
}

.disabledFieldsWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
  gap: 0.75rem;
}

.modal {
  padding: 1.3rem;
  max-height: 45rem;
  overflow: auto;
  min-width: 25rem;
  min-height: 35rem;
  overflow-y: auto;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  & > span {
    margin-right: 5px;
  }
}

.input {
  margin-top: 5px;
  margin-bottom: 5px;

  & label {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
  }
}

.buttons {
  width: 100%;
  display: flex;
  margin-top: 30px;
}
