.headerTriggers {
  width: 30%;
  min-width: 15rem;
}

.tableActionsItems {
  width: 6.25rem;
}

.headerSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 3.125rem;
  flex-wrap: wrap;
}

.chBoxes {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  gap: 1.5rem;
  padding: 1.125rem 0 0 1.5rem;
}

.tableActionCell {
  text-align: center;
  display: flex;
  justify-content: space-around;
}

.tableCellProduct {
  white-space: nowrap;
  /* Текст не переносится */
  overflow: hidden;
  /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &>div:first-child>div {
    display: flex;
    justify-content: flex-start;

    >:first-child {
      padding-left: 1rem;
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}


.tableCell {
  display: flex;
  align-items: center;
  min-width: 0;
  overflow: hidden;
  height: 44px;


  >div {
    display: flex;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
  }
}

.tableHeader {
  div>div {
    justify-content: center;
  }

  & :first-child>div {
    justify-content: flex-start;
  }

  &>div>div,
  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}