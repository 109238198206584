$accent: #0078d4;
$gray: #f4f4f4;
$lightBlue: #C7E0F4;
$red: #a80000;
$green: #4caf50;
$danger: #fde7e9;
$error: rgb(164, 38, 44);

$bgColor: '#F8F8F8';
$lighAlt: '#EFF6FC';
$lighter: '#DEECF9';
$tertiary: '#2B88D8';
$darkAlt: '#106EBE';
$dark: '#005A9E';
$darker: '#004578';

$initialCodeColor: #5589ff;
$validCodeColor: #107c10;
$invalidCodeColor: #ee5f2b;
$noReadCodeColor: #d83b01;
$dropOutColor: #d83b01;

$titlebar: #006FC5;
$headerHeight: 3rem;
$topLineHeight: 6rem;

$hoverColor: #EFF6FC;

$font: 'Segoe UI', sans-serif;