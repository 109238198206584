@import '../../assets/scss/vars';
@import '../../assets/scss/animations';

.table {
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.mainContent {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tableActionsItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // padding-right: 1rem;
  // width: 350px;
}

.tableElement {
  margin-left: 3rem;
}

.tableActionsButtons {
  display: flex;
  align-items: center;
  gap: 9px;
  color: red;

  &>div,
  p {
    font-weight: 600;
  }
}

.reportsHeader {
  margin-top: 1.25rem;
  align-items: flex-end;
}

.syncButtons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.syncBtn {
  width: 18rem;

  display: flex;
  margin-top: 0.3125rem;
}

.progressSyncBtn {
  i {
    animation: rotate 1s linear infinite alternate-reverse;
  }
}

.tableCell {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tableCellProduct {
  justify-content: flex-start;
}

.firsthThreeRows {
  height: 2.8rem;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.lastRow {
  width: 25%;
  padding-right: 1rem;
}

.firstRow {
  display: flex;
  justify-content: flex-start;
}

.middleRows {
  display: flex;
  justify-content: center;
  width: 15%;
}

.lastTableHeaderItem {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.tableHeaderItem {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  // text-align: center;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin: 0;
}

.middleTableHeaderItem {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  // text-align: center;
  display: flex;
  justify-content: center;
  margin: 0;
}

.withLineBreak {
  white-space: pre-line;
}

.tableWrapper {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.actionsRow {
  max-width: 160px;
  display: flex;
  justify-content: space-between;
}

.markingCodesRemainder {
  display: flex;
  justify-content: center;
  overflow: visible !important;

  & div,
  a,
  p {
    overflow: visible !important;
  }
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  &>div {
    display: flex;
    justify-content: center;
  }

  & div>div,
  p,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &>div:first-child {
    display: flex;
    justify-content: flex-start;

    >:first-child {
      padding-left: 1rem;
    }
  }
}

.tableHeader {
  div>div {
    justify-content: center;
  }

  & :first-child>div {
    justify-content: flex-start;
  }

  &>div>div,
  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}