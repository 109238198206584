@mixin scroll($child) {
  #{$child}::-webkit-scrollbar {
    width: 0.5rem;
    max-height: 0.5rem;
    min-width: 7px;
  }

  #{$child}::-webkit-scrollbar-track {
    background: transparent;
  }

  #{$child}::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    background: #057bd6;
    border-radius: 0;
  }
}

.fieldWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.optionsWrapper {
  width: 100%;
  max-height: 20rem;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 3px 10px 3px 3px;
}

.singleRow {
  padding: 6px 0;
  display: flex;
  gap: 2rem;
  align-items: center;
  border-top: 1px solid black;
}

.fieldLabel,
.addButton,
.addModButton {
  min-height: 2rem;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  margin: 12px 0;
  min-width: 22rem;
  max-width: 22rem;
  min-height: 2.5rem;
  flex-shrink: 0;
  background-color: rgb(0, 120, 212);
  border-radius: 2px;
  padding: 0 1rem 2px 1rem;
  color: white;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
    BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  border: 1px solid rgb(0, 120, 212);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.addButton {
  margin-top: 10px;
}

.addModButton {
  min-width: 10rem;
}

@include scroll(section);
