@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.root {
  max-height: calc(100vh - 9rem);
  height: 100%;
  overflow: auto;
}

.resultBlock {
  background-color: #fff;
  padding: 1rem;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  margin: 0.75rem 1rem;
}

.titleWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: black;
  padding: 0.75rem 1rem;
  background-color: #F3F2F1;
}

.resultBlockItem {
  display: flex;
  justify-content: space-between;
}

.resultBlockItem__gtin {
  margin-bottom: 0.75rem;
}

.resultLinkButton {
  & a button {
    text-decoration: underline;
    color: #004578;
  }
}

.resultBlockItem__status {
  display: flex;
  height: 32px;
  align-items: center;
  border-radius: 4px;
  background: #deecf9;
  margin-bottom: 0.75rem;
  padding: 0.375rem;
}

.resultBlockItem__date {
  display: flex;
  gap: 1rem;
}

.resultBlockItem__date_item {
  display: flex;
  gap: 0.5rem;
}

.descriptionBlock {
  // max-height: 180px;
  overflow: auto;
  padding: 1.5rem;
  background-color: #FAF9F8;

  & p {
    padding: 0.156rem;
    margin-bottom: 0.25rem;
    color: #000;
  }
}

.descriptionStoryBlock {
  // max-height: 250px;
  overflow: auto;
  padding: 1.5rem;
  background-color: #FAF9F8;

  & p {
    padding: 0.156rem;
    margin-bottom: 0.25rem;
    color: #000;
  }
}

@include scroll('.descriptionBlock');
@include scroll('.descriptionStoryBlock');
@include scroll('.root');

.tablist {
  margin-bottom: 0.75rem;
}

.errorMessageText {
  font-size: 12px;
  color: $error;
  font-weight: 400;
  position: absolute;
  left: 4px;
  bottom: -20px;
}

.historyStatusTitle {
  font-weight: 700;
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1.438rem;
}