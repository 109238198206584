.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.05);
}

.loaderContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filledLoader {
  background-color: #fff;
  z-index: 100;
}

.simpLoaderCont {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  opacity: 0.8;
  z-index: 5;
}

.loader {
  width: 48px;
  height: 48px;
  border: 6px solid white;
  border-bottom-color: blue;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
