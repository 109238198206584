@import "../../../../assets/scss/vars.scss";

.alert {
  background: #fff;
  padding: 1.25rem, 2rem, 1.25rem, 2rem;
  //   border-radius: 5px;
  min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 50rem;
  width: 25rem;
  text-align: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  gap: 2rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.text {
  display: flex;
  flex-direction: column;
  color: #000;
  font-family: 'Segoe UI, sans-serif';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.controls {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.btn {
  height: 2.5rem;
  border-radius: 7px;
  font-size: 1rem;
  color: #fff;
  padding: 0 0.9375rem;
  transition: all 0.3s ease;
  background: $green;
  width: 6.25rem;

  &:hover {
    background: darken($green, 5%);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
