@import '../../../assets/scss/vars';
@import '../../../assets/scss/mixins';

.permitsWrapper {
  display: flex;
  flex-direction: column;
}

.btn {
  margin-top: 0.625rem;
  padding: 0.75rem;
  max-width: 24rem;
}

.errorMsg {
  margin-top: 0.5rem;
  font-size: 12px;
  color: red;
  font-weight: 400;
  left: 0;
  bottom: -20px;
}
