.date {
  max-width: 250px;
  position: relative;

}

.filtersWrapper {
  display: flex;
  gap: 10px;
}

.error {
  color: red;
  position: absolute;
  font-size: 10px;
  bottom: -10px;
  left: 0;

}