@import '../../../assets/scss/vars';

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  justify-content: space-between;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tooltip {
  min-width: 450px;
}

.tableCell {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dragging {
    background: #f0f0f0;
    animation: draggingAnimation 0.3s ease;
  }


}

.messageWrapper {
  display: flex;
  flex-shrink: 0;
  min-width: 30rem;
  width: 40%;
}

.tooltipContainer {
  margin-left: 0.2rem;
}

.bottomPart {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  align-items: flex-end;
}

.correctionText {
  display: flex;
  align-self: self-end;
  font-family: 'Segoe UI', sans-serif;
}

.custHeaderCell {
  background-color: orange;
}

@keyframes draggingAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.tableContainer {
  background: #FFF;
  position: relative;
  height: 100%;
  min-height: 0;
  overflow: auto;
  padding: 0.5rem;
  border-radius: 0.5rem;

  &::-webkit-scrollbar {
    width: .5rem;
    max-height: 0.5rem;
    min-width: 7px;
    z-index: 2;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
    margin-top: 44px;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}

.table {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tableContent {
  overflow: auto;
  min-height: 0;
  padding-right: 0.4375rem;
  background-color: #ffffff;
  position: relative;

  td {
    height: 43px;
    max-height: 43px;
    min-height: 43px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}

.tableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
  border-collapse: collapse;
  text-align: center;
  max-height: 44px;
  min-height: 44px;
  height: 44px;
  margin-right: 0;
  border-bottom: 1px solid #e9e9e9;
  padding-right: 0.4375rem;

  &>div {
    display: flex;
    justify-content: space-between;
  }

  div>div {
    display: flex;
    align-items: center;
    height: 43px;
    padding: 0;
    white-space: wrap;
    text-overflow: ellipsis;
    justify-content: center;
  }

}

.sortIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableRow {
  max-height: 44px;
  min-height: 44px;
  height: 44px;
  position: relative;
  display: flex;
  width: 100%;
  cursor: pointer;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    padding: 0;

    & img {
      margin: auto;
    }

    & div,
    p,
    button,
    a,
    span {
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.tableSelectionCell {
  border-bottom: 1px solid #0f6cbd !important;
}

.tableWrapper {
  height: 100%;
}

.tableBlock {
  display: flex;
  padding: 1rem;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  min-height: 0;
  position: relative;
}

.disabledCheckbox {
  cursor: not-allowed;
  opacity: 0.5;

  & span {
    cursor: not-allowed;
  }
}

.copyIconWrapper {}

.copyIcon {
  --icon-color: #605E5C;
  cursor: copy;

  &:hover {
    --icon-color: #0078D4;
  }
}

@media (max-width: 1100px) {
  .table {
    font-size: 11px;
  }

  .table button {
    font-size: 11px;
  }

  .table img {
    height: 34px;
    width: 90px;
  }



  #partNumber {
    & div img {
      min-height: 10px;
      max-width: 20px;
    }
  }
}

//здесь указываем ширину столбца и выравнивание заголовка и содержимого столбца
#productName {
  width: 15%;
  overflow: hidden;
  max-width: 200px;
  overflow: hidden;
  margin-left: 10px;

  div {
    justify-content: flex-start;
  }

  & button,
  p,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

#numberMarkingCodes {
  overflow: hidden;
}

#status {
  min-width: 12%;

  & img {
    height: calc(70% - 10px);
    width: calc(80% - 10px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#partNumber {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

#creationDate {
  overflow: hidden;

}

#sendingDate {
  overflow: hidden;

}

#productCreationDate {}

#numberMarkingCodes {
  overflow: hidden;
  justify-content: center;
}

#productGtin {
  overflow: hidden;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

}

#vsd {
  overflow: hidden;
}

#suzId {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

#partNumber {
  overflow: hidden;
}

#serialNumber {
  overflow: hidden;
}

#productCreationDate {
  overflow: hidden;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

.searchNothing {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

// Добавьте стили для react-window
.ReactWindow {
  flex: 1 1 auto;
  outline: none;
  overflow-x: visible;
  padding-right: 0.4375rem;
  overflow: hidden !important;

  &::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
  }

  &::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}

// ... existing styles ...

.tableHeaderCell {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  display: flex;

  &.dragging {
    background: #f0f0f0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }

  & div,
  p {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.linkButton {
  margin-left: 30px;

  &:hover {
    .iconColor {
      --icon-color: black;
    }
  }
}

.iconColor {
  --icon-color: white;
  width: 24px;
  height: 24px;
}

// Добавляем стили для drag handle
.dragHandle {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: move;
  padding: 0 8px;
  user-select: none;
}

.resizeHandle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  cursor: col-resize;
  user-select: none;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }

  &::after {
    content: '';
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 50%;
    width: 1px;
    background: #e9e9e9;
  }
}

.resizing {
  cursor: col-resize;
  user-select: none;

  * {
    user-select: none;
  }

  .resizeHandle {
    &::after {
      background: #0078D4;
      top: 15%;
      bottom: 15%;
      width: 2px;
    }
  }

}

.draggingColumn {
  background: rgba(0, 120, 212, 0.05);

  &.dragging {
    background: rgba(0, 120, 212, 0.1);
    transform: scale(1.01);
  }
}

.tableHeaderItem {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  text-align: center;
  padding: 0 8px;
  margin: 0;
  background: #FFF;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dragging {
    background: #f0f0f0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
}

@keyframes draggingAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}