@import "../../assets/scss/vars";

.headerTitle {
  width: 100%;
  padding-bottom: 1.25rem;
  font-weight: 700;
  border-bottom: 1px solid #E9E9E9;
}

.lineTableFooter {
  margin-top: 0.9375rem;
  display: flex;
  justify-content: space-between;
}

.tables {
  padding-bottom: 6.25rem;
}

.buttons {
  left: 0;
  position: fixed;
  background: #F8F8F8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.375rem;
  bottom: 0;
}

.inputRow {
  width: 18.75rem;
  margin-bottom: 1.25rem;
}

.error {
  color: $red;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
}

.addBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tableCellProduct {
  white-space: nowrap;
  /* Текст не переносится */
  overflow: hidden;
  /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  & div>div,
  p,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &>div:first-child {
    display: flex;
    justify-content: flex-start;

    >:first-child {
      padding-left: 1rem;
    }
  }

  &>div:first-child>div {
    display: flex;
    justify-content: flex-start;
  }
}

.tableCell {
  display: flex;
  align-items: center;
  min-width: 0;
  overflow: hidden;
  height: 44px;

  >div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tableHeader {
  div>div {
    justify-content: center;
  }

  & :first-child>div {
    justify-content: flex-start;
  }

  &>div>div,
  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}