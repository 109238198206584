@mixin scroll($child) {
  #{$child}::-webkit-scrollbar {
    width: 0.5rem;
    max-height: 0.5rem;
    min-width: 7px;
  }

  #{$child}::-webkit-scrollbar-track {
    background: transparent;
  }

  #{$child}::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    background: #057bd6;
    border-radius: 0;
  }
}

.componentMainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.placesMainWrapper {
  display: flex;
  flex-direction: column;
  // border: 1px solid black;
  // border-bottom: none;
  padding: 5px;
}

.singleRowWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  min-height: 100%;
}

.singleRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px 0;
  border-bottom: 1px solid black;
}

.fieldTitle {
  font-size: 'Segoe UI', sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 24px;
}

.singleOptionSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  height: 100%;
  border: 1px solid black;
  padding: 2px 4px;
}

@mixin optionWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 3;
  height: 20rem;
  overflow-y: scroll;
  cursor: pointer;

  :hover {
    filter: brightness(80%);
  }
}

.optionMainWrapper {
  @include optionWrapper;
}

.optionMainWrapperOne {
  @include optionWrapper;
  height: auto;
}

.singleOption_gray {
  margin-top: 5px;
  width: 100%;
  background-color: #f4f4f4;
}

.singleOption_blue {
  margin-top: 5px;
  width: 100%;
  background-color: #c7e0f4;
}

.optionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 8px;
  font-size: 'Segoe UI', sans-serif;
  font-size: 18;
  font-weight: 700;
  gap: 0.5rem;
}

.groupName {
  font-size: Segoe UI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  min-width: 25%;
  max-width: 30%;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 25;
}

.optionFieldName,
.optionFieldValue {
  font-size: 'Segoe UI', sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  width: 27%;
  flex-grow: 2;
}

.optionFieldValue {
  display: flex;
  width: 73%;
}

.emptyFieldMsg {
  font-size: 'Segoe UI', sans-serif;
  font-size: 15px;
  font-weight: 500;
  height: 24px;
}

.selectedPlaceWrapper {
  display: flex;
  width: 55%;

  max-width: 55%;
  flex-direction: column;
}

.selectedPlaceProp {
  width: 100%;
  display: flex;
  padding: 0.2rem;
  gap: 0.5rem;
}

.crossLine {
  margin-top: 0.2rem;
  display: flex;
  align-self: center;
  width: 6rem;
  border-bottom: 1px solid black;
}

.POBfieldName {
  font-size: 'Segoe UI', sans-serif;
  font-size: 13px;
  font-weight: 600;
  display: flex;
}

.noModSelected {
  font-size: Segoe UI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 5;
}

@include scroll(main);