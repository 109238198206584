.buttons {
  display: flex;
  width: auto;
  margin-top: 1.25rem;
}

.btn {
  margin-right: 0.625rem;
  width: 9.375rem;
}
