.autoUpdateButton {
  display: flex;
  width: 12rem;
  height: 1.875rem;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Red, #ee5f2b);
  margin: auto;
  cursor: pointer;
}

.autoUpdateButtonText {
  color: #fff;
  // leading-trim: both;
  // text-edge: cap;
  // font-family: Segoe UI;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 257.143% */
  padding: 0 0.2rem;
}

.emptyDiv {
  height: 4rem;
}

.btn {
  align-self: center;
  width: 7rem;
  max-height: 2.5rem;
  border-radius: 0.3rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;  
    background: #ECECEC;
      color: #222B45;
}

.btn:hover {  
    background: darken(#ECECEC, 5%);  
}

.mainCountDownWrapper { 
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

}