@import '../../../assets/scss/mixins.scss';

.modal {
  padding: 1.5rem;
  // overflow: auto;
  min-width: 30rem;
  max-width: 45vw;
  min-height: 35rem;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  div {
    overflow: visible;
  }
}

.label {
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  width: 100%;
}

.textInput {
  width: 100%;
  margin-bottom: 0.5rem;
}

.btn {
  width: 50%;
  margin-top: 2rem;
  align-self: center;
}

.mainButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.3125rem;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
}

.checkbox {
  margin-top: 1.5625rem;
}

@include scroll('.modal');
