.mainInputs {
  width: 60%;
}

.infoRow {
  position: relative;
}
.inputsContainer {
  display: flex;
  justify-content: space-between;
}

.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 30%;
  height: fit-content;
}

.deleteConsSection {
  display: flex;
  align-self: center;
  margin-top: 1.25rem;
}

.warningText {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: auto;
  text-align: justify;
  font-family: 'Segoe UI', sans-serif;
  font-size: 1rem;
}

.buttonsRow {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.deleteButton {
  min-width: 100%;
}
