@import '../../assets/scss/mixins';
@import '../../assets/scss/vars';

@mixin tableText {
    font: {
        family: 'Segoe UI';
        style: normal;
        weight: 350;
        size: 0.975rem;
        color: #333333;
    }

    line-height: 1.25rem;
}

.tableActionCell {
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.mainContainer {
    margin-top: 3.125rem;
}

.additionalHeaderProps {
    justify-content: flex-start;
}

.balanceTableColumns {
    justify-content: flex-start;
    @include tableText;
}

.balanceTitle {
    width: 14, 625rem;
    height: 1.25rem;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #333333;
}

.titleWrapper {
    margin-bottom: 1.25rem;
}

.minBalanceInput {
    width: 10rem;
    height: 1.625rem;
    display: flex;
    justify-content: flex-start;
    // background-color: #FFFFFF;
    color: #201F1E;
    border: 0.5px solid #201F1E;
    border-radius: 2px;
    padding-left: 0.625rem;
    padding-top: 0.4375rem;
    padding-bottom: 0.4375rem;
    @include tableText;
}

.lowBalance {
    background-color: #FDE7E9;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 6.6875rem;
    padding-right: 1.2rem;
}

.scrollable {
    overflow: auto;
}

@include scroll('.scrollable');

/* New styles based on Aggregates.module.scss */
.tableItem {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;

    & div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &>div:first-child>div {
        display: flex;
        justify-content: flex-start;

        >:first-child {
            padding-left: 1rem;
        }
    }
}

.tableCell {
    display: flex;
    align-items: center;
    min-width: 0;
    overflow: hidden;
    height: 44px;
    @include tableText;
    justify-content: center;
    >div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


.tableHeader {
    div>div {
        justify-content: center;
    }

    & :first-child>div {
        justify-content: flex-start;
    }

    &>div>div,
    span:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    overflow: hidden;
}

.tableWrapper {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}