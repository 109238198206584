.calloutWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: underline;
  color: #0078d4;
  cursor: pointer;
}

.informationBoxUP {
  z-index: 15;
  position: absolute;
  display: flex;
  left: calc(50% - 7rem);
  top: calc(100% - 9.5rem);
  width: auto;
  padding: 15px 12px;
  background-color: #f8f8f8;
  padding: 5px 12px;
  border-radius: 0.5rem;
  color: #201f1e;
  box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001a;

  &::after {
    border: 0.9rem solid;
    border-color: #f8f8f8 transparent transparent;
    content: '';
    left: 45%;
    margin-left: -10px;
    position: absolute;
    top: 100%;
  }
}

.informationBoxBottom {
  z-index: 15;
  position: absolute;
  display: flex;
  left: calc(50% - 7rem);
  top: calc(3rem - 100%);
  padding: 15px 12px;
  background-color: #f8f8f8;
  padding: 5px 12px;
  border-radius: 0.5rem;
  color: #201f1e;
  box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001a;

  &::before {
    border: 0.9rem solid;
    border-color: transparent transparent #f8f8f8 transparent;
    content: '';
    left: 45%;
    margin-left: -10px;
    position: absolute;
    top: -20%;

  }
}

.informationBoxProdPage {
  z-index: 15;
  position: absolute;
  display: flex;
  top: -8rem;
  left: -0.7rem;
  width: 14rem;
  height: 7rem;
  padding: 15px 12px;
  background-color: #f8f8f8;
  padding: 5px 12px;
  border-radius: 0.5rem;
  color: #201f1e;
  box-shadow: 0px 1.2000000476837158px 3.5999999046325684px 0px #0000001a;

  &::after {
    border: 0.9rem solid;
    border-color: #f8f8f8 transparent transparent;
    content: '';
    left: 75%;
    margin-left: -10px;
    position: absolute;
    top: 100%;
  }
}

.longerString {
  left: 0;
}

.longString {
  left: 1rem;
}

.callout {
  width: 15rem;
  height: 9.5rem;
  padding: 0.5rem;
  overflow: auto;
}

.loading {
  -webkit-font-smoothing: antialiased;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  font-family: 'Segoe UI';
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.backDrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  opacity: 0.2;
  z-index: 6;
}