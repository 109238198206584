.logContainer {
  display: flex;
  margin-bottom: 10px;
}

.logTitle {
  font-weight: bold;
  width: 15%;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.paginationStart {
  margin-right: 15px;
}

.chevronIcons {
  width: 1rem;
  height: auto;
  align-self: center;
  margin-top: 0.3rem;
}

.pageNumber {
  display: flex;
  justify-content: center;
  align-items: center;
}
