@import "../../../assets/scss/vars";

.remainsInputs {
  display: flex;
  align-items: center;
}

.remainsInput {
  width: 4.375rem;
}

.remains {
  margin-top: 3.125rem !important;
}

.line {
  margin: 0 0.625rem 0.3125rem;
  line-height: 1;
}

.error {
  color: $red;
  display: block;
  margin-top: 0.625rem;
}
