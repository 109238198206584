@import '../../assets/scss/mixins';
@import '../../assets/scss/vars';


.title {
  margin-bottom: 1.25rem;
}

.form {
  width: 100%;
}

.company,
.suz,
.gismt,
.catalog {}

.infoRow {
  width: 40rem;
  margin-bottom: 1.875rem;
  &Inputs {
    display: flex;
  }
  &Title {
    font-weight: 700;
  }
}

.templates {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 1.875rem;
}

.templateInput {
  width: 300px;
  margin-right: 1.875rem;
  &:last-child {
    margin-right: 0;
  }
}

.rowDropdown {
  width: 15rem;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  margin: 0.6rem 0;
}

.singleCheckbox {
  margin: 1rem 0 0 0;
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.contentWrapper {
  // min-height: calc(100vh - 9rem);
  background: #fff;
  padding: 1.875rem 3.75rem;
  height: calc(100vh - 12rem);
  width: calc(100%);
  position: relative;
  overflow: auto;
}

@include scroll('.contentWrapper');

.checkboxText {
  text-align: center;
  font-size: 14px;
}

.fieldTitle {
  font-size: 14px;
  font-weight: 600;
}
