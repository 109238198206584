@import "../../assets/scss/mixins";

@include scroll('.tables');

.tables {
  height: 100%;
  overflow: auto;
}

.header {
  margin-top: 1.875rem;
}

.headerTitle {
  width: 100%;
  padding-bottom: 1.25rem;
  font-weight: 700;
  border-bottom: 1px solid #E9E9E9;
}

.userInfo {
  margin-top: 0.9375rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
}

.changePassword {
  margin-top: 2.5rem;
}

.changePasswordTitle {
  font-weight: 500;
  font-size: 1rem;
}

.passwords {
  margin-top: 0.9375rem;
}

.changePasswordBtn {
  margin: 1.5625rem 0;

}
