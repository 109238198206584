@media (min-width: 1800) and (max-width: 2000px) {
  html {
    font-size: 20px;
  }
}

@media (min-width: 1600) and (max-width: 1800px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1550) and (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1500) and (max-width: 1550px) {
  html {
    font-size: 15.5px;
  }
}

@media (min-width: 1450) and (max-width: 1500px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1400) and (max-width: 1450px) {
  html {
    font-size: 14.5px;
  }
}

@media (min-width: 1350) and (max-width: 1400px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1300) and (max-width: 1350px) {
  html {
    font-size: 13.5px;
  }
}

@media (min-width: 1250) and (max-width: 1300px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1200) and (max-width: 1250px) {
  html {
    font-size: 12.5px;
  }
}

@media (min-width: 1024) and (max-width: 1200px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  html {
    font-size: 11px;
  }
}
