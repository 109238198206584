// @font-face {
//   font-family: 'AkzidenzGroteskPro';
//   src: local('AkzidenzGroteskPro'),
//        url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf') format('truetype'),
//        url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
// }

.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f8f8f8;
}

.form {
  width: 400px;
  position: relative;
}

.panel {
  padding: 1.875rem 3.125rem 3.125rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  width: 6.25rem;
  margin-left: auto;
}

.error {
  color: red;
  font-size: 0.875rem;
}

.logoCompanyName {
  margin-top: 25px;
  margin-bottom: 25px;
  color: #000000;
  font-family: 'AkzidenzGroteskPro';
  font-style: normal;
  font-weight: normal;
  font-size: 30.0421px;
  line-height: 38px;
  /* identical to box height */

  letter-spacing: 0.145em;
}

.pageDescription {
  width: 428px;
  height: 56px;
  font-family: 'Segoe UI';
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #323130;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 24px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  width: 400px;

  div:not(:last-child) {
    width: 100%;
    height: 32px;
    align-self: center;
    margin-bottom: 35px;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
