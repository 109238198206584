.checkBoxWrapper {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  margin: 2rem 2rem;
}

.singleCheckbox {
  display: flex;
  gap: 0.2rem;
  align-items: center;
}

.checkboxText {
  text-align: center;
}

.tableHeader {
  & div:first-child>div {
    display: flex;
    justify-content: flex-start;
  }

  div>div {
    justify-content: center;
  }

  & :first-child>div {
    justify-content: flex-start;
  }

  &>div>div,
  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  & div>div,
  p,
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &>div:first-child {
    display: flex;
    justify-content: flex-start;

    >:first-child {
      padding-left: 1rem;
    }
  }

  &>div:first-child>div {
    display: flex;
    justify-content: flex-start;
  }
}

.tableActionCell {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.tableCell {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.checkBoxWrapper {
  display: flex;
  margin: 0.5rem 0 0.5rem 1rem;
  gap: 1rem;
}

.singleCheckbox {
  display: flex;
  align-items: center;
}

.checkboxText {
  margin-left: 0.5rem;
}

.productsActionsItems {
  display: flex;
  justify-content: center;
}