.tableActionCell {
    text-align: center;
    display: flex;
    justify-content: space-around;
}

.tableCellProduct {
    white-space: nowrap;
    /* Текст не переносится */
    overflow: hidden;
    /* Обрезаем всё за пределами блока */
    text-overflow: ellipsis;
}

.tableItem {
    height: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;

    & div>div,
    p,
    a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }

    & :first-child {

        justify-content: flex-start;
    }

    &>div:first-child>div {
        display: flex;
        justify-content: flex-start;

        >:first-child {
            padding-left: 1rem;
        }
    }

    &>div:first-child {
        display: flex;
        justify-content: flex-start;
    }
}


.tableHeader {
    div>div {
        justify-content: center;
    }

    & :first-child>div {
        justify-content: flex-start;
    }

    &>div>div,
    span:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.tableCell {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    overflow: hidden;
}

.mainContent {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.tableWrapper {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}