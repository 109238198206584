.infoRow {
  width: 80%;
}

.templates {
  display: flex;
}

.templateInput {
  width: 300px;
  margin-right: 1.875rem;
}