@import '../../assets/scss/vars';


.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 9rem);
}

.inputBlock {
  display: flex;
  width: 603px;
  position: relative;
}

.buttonBlock {}

.errorMessageText {
  font-size: 12px;
  color: $error;
  font-weight: 400;
  position: absolute;
  left: 4px;
  bottom: -20px;
}