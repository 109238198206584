.panel {
  border-radius: 2px;
  padding: 1.875rem 2.5rem 1.875rem 0;
  font-weight: 500;
}

.panelTitle {
  font-size: 1.125rem;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
}

