.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fieldWrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.input {
  min-width: 3rem;
  max-width: 3rem;
  margin: 0 0.6rem;
  align-self: center;
  margin-top: -0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.fieldDescrp {
  display: flex;
  flex-shrink: 0;
  margin-left: 0.5rem;
}

.error {
  display: flex;
  color: red;
  margin: 0.4rem 0;
}

.btn {
  margin-top: 1rem;
}

.inputClassName {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.info {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.infoParag {
  display: flex;
  align-items: center;
  text-align: justify;
  text-justify: inter-word;
}
