.extraConsignmentInfo {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  
}

.infoRow{
  line-height: 1.8rem;
  display: flex;
  justify-content: space-between;
}

.infoCell{
  margin-right: 1rem;
}
