.expiration {
  display: flex;
  flex-direction: column;
  // gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
