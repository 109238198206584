@import '../../assets/scss/vars';

.container {
  width: 40%;
  padding: 0;
}

.buttons {
  margin-top: 3.75rem;
  display: flex;
  justify-content: flex-end;
}

.title {
  height: 2.75rem;
  display: flex;
  align-items: center;
  padding-left: 1.25rem;
  line-height: 1;
  padding-bottom: 0.3125rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  background: $accent;
  width: 100%;
}

.body {
  position: relative;
  padding: 1.875rem 1.25rem;
}
