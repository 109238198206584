.modal {
  padding: 20px;
  max-height: 700px;
  overflow: auto;
}
.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  & > span {
    margin-right: 5px;
  }
}
.input {
  margin-top: 5px;
  margin-bottom: 5px;

  &  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
  }
}

.buttons {
  width: 100%;
  display: flex;
  margin-top: 30px;
}