.tableCell {
  display: flex;
  align-items: center;
  min-width: 0;
  overflow: hidden;
  height: 44px;

  >div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tableItem {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & :first-child {

    justify-content: flex-start;
  }

  &>div:first-child>div {
    display: flex;
    justify-content: flex-start;

    >:first-child {
      padding-left: 1rem;
    }
  }
}

.tableHeader {
  div>div {
    justify-content: center;
  }

  & :first-child>div {
    justify-content: flex-start;
  }

  &>div>div,
  span:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tableActionCell {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.tableCellProduct {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}

.mainContent {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  flex: 1;
  min-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}