@import "vars";

@mixin scroll($child) {

  #{$child}::-webkit-scrollbar {
    width: .5rem;
    max-height: 0.5rem;
    min-width: 7px;
    z-index: 2;
  }

  #{$child}::-webkit-scrollbar-track {
    background: transparent;
    z-index: 2;
  }

  #{$child}::-webkit-scrollbar-thumb {
    border-left: 1px solid white;
    z-index: 2;
    background: $accent;
    border-radius: 0;
  }
}